import { createBrowserRouter } from "react-router-dom";
// pages
import PasswordReset from "../page/password-reset/PasswordReset";

const router = createBrowserRouter([
	{
		path: "/reset-password",
		element: <PasswordReset />,
	},
]);

export default router;
