import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, ChangeEvent } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface PasswordFormType {
	onSubmit: (formData: { password: string }) => void;
}

function PasswordForm({ onSubmit }: PasswordFormType) {
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};

	const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(event.target.value);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&-_]{8,}$/;

		if (!password.match(passwordRegex)) {
			setError(
				"Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one of the following special characters: @, $, !, %, *, ?, &, -, _"
			);
		} else if (password !== confirmPassword) {
			setError("Passwords do not match");
		} else {
			onSubmit({ password });
			setError("");
		}
	};

	return (
		<Card sx={{ width: 296 }}>
			<CardContent>
				<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
					Bridge Business Club
				</Typography>
				<Typography variant="h5" component="div">
					Password reset
				</Typography>
				<br />
				<Typography variant="body2">Type new password</Typography>
				<FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
					<InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
					<OutlinedInput
						fullWidth
						id="outlined-adornment-password"
						type={showPassword ? "text" : "password"}
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label="New Password"
						value={password}
						onChange={handlePasswordChange}
					/>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 2, mb: 2 }} variant="outlined">
					<InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
					<OutlinedInput
						fullWidth
						id="outlined-adornment-password"
						type={showPassword ? "text" : "password"}
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label="Confirm Password"
						value={confirmPassword}
						onChange={handleConfirmPasswordChange}
					/>
				</FormControl>
				{error && (
					<Typography variant="body2" color="error">
						{error}
					</Typography>
				)}
				<br />
				<Button variant="contained" fullWidth onClick={handleSubmit}>
					Save
				</Button>
			</CardContent>
		</Card>
	);
}

export default PasswordForm;
