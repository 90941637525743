import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import cn from "classnames";
import ps from "./css/pass-reset.module.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessCard = () => {
	return (
		<Card>
			<CardContent>
				<Box className={cn(ps.success)}>
					<CheckCircleIcon color="success" sx={{ fontSize: 48 }} />
					<Typography variant="h5" component="div">
						Success!
					</Typography>
					<br />
					<Typography variant="body2" color="text.secondary">
						Your password has been reset. <br /> Now you can return to the app
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default SuccessCard;
