import cn from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import ps from "./css/pass-reset.module.scss";
import { API, storeATokenD } from "../../queries/api";
import PasswordForm from "./PasswordForm";
import { useEffect, useState } from "react";
import SuccessCard from "./SuccessCard";
import ErrorCard from "./ErrorCard";
import { getTokenFromURL } from "../../utils";

interface PasswordSubmitType {
	password: string;
}

const STAGES = {
	FORM: "F",
	SUCCESS: "S",
	ERROR: "E",
	LOADING: "L",
};

export default function PasswordReset() {
	const [stage, setStage] = useState(STAGES.FORM);
	const navigate = useNavigate();

	useEffect(() => {
		const token = getTokenFromURL("token");

		if (token) {
			// TODO: don't store token in local storage, use only for this request
			storeATokenD(token);
			navigate(window.location.pathname);
		}
	}, [navigate]);

	const updatePassword = async ({ password }: PasswordSubmitType) => {
		try {
			setStage(STAGES.LOADING);
			const response = await API.post(`password/change`, {
				password,
			});
			if (response.status === 200) {
				setStage(STAGES.SUCCESS);
			} else {
				setStage(STAGES.ERROR);
			}
		} catch (e) {
			setStage(STAGES.ERROR);
			return console.error(e);
		}
	};

	const onFormSubmit = ({ password }: PasswordSubmitType) => {
		updatePassword({ password });
	};

	const content = (() => {
		switch (stage) {
			case STAGES.FORM:
				return <PasswordForm onSubmit={onFormSubmit} />;
			case STAGES.SUCCESS:
				return <SuccessCard />;
			case STAGES.ERROR:
				return <ErrorCard />;
			case STAGES.LOADING:
			default:
				return <CircularProgress />;
		}
	})();

	return <div className={cn(ps.page)}>{content}</div>;
}
