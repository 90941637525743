import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import cn from "classnames";
import ps from "./css/pass-reset.module.scss";
import ErrorIcon from "@mui/icons-material/Error";

const ErrorCard = () => {
	return (
		<Card>
			<CardContent>
				<Box className={cn(ps.error)}>
					<ErrorIcon color="error" sx={{ fontSize: 48 }} />
					<Typography variant="h5" component="div">
						Error!
					</Typography>
					<br />
					<Typography variant="body2" color="text.secondary">
						Something went wrong. Please try again later.
					</Typography>
					<Typography variant="body2" color="text.secondary">
						If the issue persists, contact us at <a href="mailto:support@bridgesbusiness.club">support@bridgesbusiness.club</a>
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ErrorCard;
